@import "../../assets/styles/utils";
footer {
    .top {
        display: flex;
        @include res(min-height, 510px, (xs:auto));
        @include res(height,510px,(xs:auto));
        .logo {
            align-items: center;
            justify-content: center;
            background: #F4EDE2;
            @include res(width,27.24%);
            @include res(display,flex,(sm:none));
        }
        .right {
            position: relative;
            background: #B28341;
            @include res(width, 72.76%,(sm:100%));
            .nav {
                position: relative;
                z-index: 10;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                @include res(padding-top,90px,(xs:30px));
                @include res(padding-left, 9.28%,(xs:3.125%));
                @include res(padding-right, 9.28%,(xs:3.125%));
                @include res(justify-content,start,(lg:space-between));
                dl {
                    @include res(width,fit-content,(xs:33%));
                    @include res(margin-right, 9.28%,(lg:0));
                    @include res(margin-bottom, 0, (lg:30px));
                    &:last-child {
                        margin-right: 0;
                    }
                    dt {
                        font-weight: bold;
                        @include res(font-size,20px, 18 / 20);
                        color: #f4d296;
                    }
                    dd {
                        @include res(font-size, 16px, 14 / 16);
                        color: #ffffff;
                        @include res(margin-top, 15px, 5 / 15);
                        a{
                            display: inline-block;
                            position: relative;
                            @include res(padding-top, 5px);
                            @include res(padding-bottom, 5px);
                            &::after,&::before{
                                content: '';
                                display: block;
                                width: 0;
                                height: 1px;
                                background-color: #fff;
                                transition: all .3s;
                                position: absolute;
                                left: 0;
                            }
                            &::before{
                                top: 0;
                            }
                            &::after{
                                bottom: 0;
                            }
                        }
                        &:hover {
                            a{
                                &::after,&::before{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }
    }
    .bottom {
        box-sizing: border-box;
        @include res(padding-left,3.125%);
        @include res(padding-right, 3.125%);
        @include res(height,80px,60 / 80);
        background: #0A6969;
        align-items: center;
        @include res(display, flex, (xs:block));
        justify-content: space-between;
        .left {
            @include res(font-size, 16px, 14 / 16);
            color: rgba($color: #f4d296, $alpha: 0.8);
            @include res(padding-top,0,(xs:10px));
            @include res(margin-bottom, 0, (xs:10px));
            text-align: center;
            a{
                position: relative;
                transition: all .3s;
                &:before,
                &:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    top: auto;
                    bottom: -5px;
                    height: 2px;
                    background-color: #0A6969;
                    opacity: 0.3;
                }
                &:after {
                    width: 0;
                    left: auto;
                    right: 0;
                    opacity: 1;
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    background-color: rgba(244, 210, 150, .8);
                }
                &:hover {
            
                    &:after {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
        .right {
            text-align: center;
            @include res(font-size, 16px, 14 / 16);
            @include res(text-align, left, (xs:center));
            @include res(margin, 0, (xs:auto));
            color: #f4d296;
            .site-map {
                margin-right: 5px;
            }
            a {
                position: relative;
                transition: all .3s;
                &:before,
                &:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    top: auto;
                    bottom: -5px;
                    height: 2px;
                    background-color: #0A6969;
                    opacity: 0.3;
                }
                &:after {
                    width: 0;
                    left: auto;
                    right: 0;
                    opacity: 1;
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    background-color: rgba(244, 210, 150, .8);
                }
                &:hover {
                    &:after {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
}